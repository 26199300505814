import React from "react";
//import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import PackItem from "./PackItem";
import ItemUpdateForm from '../ItemUpdateForm';

import {v4 as uuidv4} from "uuid";
import { t } from "i18next";

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class PackItemUpdateForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
             currentStep: this.props.myShopApiStore.stepNum ? this.props.myShopApiStore.stepNum : 1,
             stepsAmount: this.props.myShopApiStore.cartItemUpdating.steps.length,
             error: false
        }
    }

    handleAddPackItemClick = (packItem, stepNum) => {
        const { openPackItemForm,
                cartItemUpdating,
                calcTotalCartPrice,
                packItemUpdating,
                closeCartItemForm } = this.props.myShopApiStore;
        const isEditableItem = packItem.item.shopProductOptions.length || packItem.item.shopToppings.length || packItem.item.shopBaseToppings?.length;

        if(packItem.isReadyToOrder){
            // packItem.isReadyToOrder = true;
            isEditableItem && openPackItemForm(packItem);
        }
        else{
            cartItemUpdating.steps[stepNum-1].packItems.forEach(packItem => packItem.isReadyToOrder = false);
            packItem.isReadyToOrder = true;

            const isTheLastStep = this.state.stepsAmount === stepNum;
            if(isEditableItem){
                openPackItemForm(packItem);
            }
            else if(isTheLastStep){
                closeCartItemForm(true);
            }
            else{
                this.nextStep();
            }

        }
        calcTotalCartPrice();
    }

    checksProductSelected = () => {
        const { stepNum } = this.props.myShopApiStore;
        const { steps } = this.props.myShopApiStore.cartItemUpdating;
        let isSelected = false;

        steps.forEach((step, s_idx) => {
            if(s_idx === stepNum-1){
                isSelected = step.packItems.some(p_item => p_item.isReadyToOrder);
            }
        })
        !isSelected && this.setState({error: true})
        return isSelected;
    
    }

    nextStep = () => {
        const { stepNum, setStepNum } = this.props.myShopApiStore;
        if(this.checksProductSelected()){
            this.setState({error: false});
            (stepNum < this.state.stepsAmount) && setStepNum( stepNum + 1);
        }
    }

    skipStep = () => {
        const { stepNum, setStepNum } = this.props.myShopApiStore;
        const { steps } = this.props.myShopApiStore.cartItemUpdating;
        const step = steps[stepNum-1];
        const stepJump = step.productsAmount - step.itemNum + 1;
        step.canSkip 
            && (
                ((stepNum + stepJump) > this.state.stepsAmount && this.props.myShopApiStore.closeCartItemForm(true))
                || ((stepNum + stepJump) <= this.state.stepsAmount 
                    && setStepNum(stepNum+stepJump) && this.setState({error: false})) 
                    
            );
        
    }

    prevStep = () => {
        const { stepNum, setStepNum } = this.props.myShopApiStore;
        (stepNum > 1) && setStepNum(stepNum - 1) && this.setState({ error: false });
    }

    // renderStepBar = (stepBar) => {
    //     const { stepNum, setStepNum } = this.props.myShopApiStore;
    //     return(
    //         <React.Fragment key={stepBar.stepNum}>
    //             {stepBar.stepNum !== 1 && <>&nbsp;- -&nbsp;</>}
    //             <div className={style.StepBar} 
    //                 style={{ color: 
    //                             stepBar.stepNum === stepNum && "#5c5c5c",
    //                          border:
    //                             stepBar.stepNum === stepNum ? "1px solid #5c5c5c" :"1px solid #dcdcdc"
    //                        }}
    //                 onClick={()=>{/*this.state.currentStep>stepBar.stepNum && */ setStepNum(stepBar.stepNum)}}>
    //                 {stepBar.stepNum}
    //             </div>
    //         </React.Fragment>
    //     )
    // }

    skipStepButton = () => {
        return (
            <div className={style.SkipContainer}>
                <div className = {style.SkipStepButton}>
                        <div style={{backgroundColor: this.props.myShopApiStore.mainColor}} onClick={this.skipStep}>{t('Skip')}</div>
                </div>
            </div>
        );
    }

    cancleCartItem = () => { //remove init edit cart-pack-item (is not UNUPDATED cart-pack-item)
        const { closeCartItemForm, isOpenForEdit, removeCartItem, thisCart } = this.props.myShopApiStore;

        closeCartItemForm()
        
        if(!isOpenForEdit){ // if its the initial edit -> remove from cart!
            const { cartItemList } = thisCart;
            const idx = cartItemList.length - 1;
            removeCartItem(idx);
        }
    }

    render(){
        const { cartItemUpdating, isPackItemUpdating, stepNum } = this.props.myShopApiStore;
        const { steps } = cartItemUpdating;
        const { toppingsFree, packItems } = steps.length > stepNum ? steps[stepNum-1] : {};
        const { t } = this.props;
        return(
            <div className={style.Container}>
                {
                    isPackItemUpdating && toppingsFree?.length
                    ? <ItemUpdateForm toppingsFree={toppingsFree} checkForProductsAssociated={this.props.checkForProductsAssociated} nextStep={this.nextStep} currentStep={stepNum} isLonely={/*steps[this.state.currentStep].*/ packItems?.length === 1}/>
                    : <div>
                        <div className={style.CloseButton}>
                            <button title={t('close')} onClick={this.cancleCartItem}>✕</button>
                        </div>
                        <div>
                            {
                                steps.map((step, lIdx) => {
                                    return(
                                        <div key={lIdx}>
                                            {
                                                stepNum === step.stepNum &&
                                                (<div>
                                                    <div className={style.StepDescription}>{step.description}</div>
                                                    {step.productsAmount > 1 && <div className={style.ItemNumber}>{t('item number')} {step.itemNum}</div>}
                                                        {/* <div className={style.StepsBar}>
                                                            {steps.map(this.renderStepBar)}
                                                        </div> */}
                                                    <div>
                                                       
                                                        {step.packItems.map((packItem,pIdx) => {
                                                            return (
                                                                <div key={pIdx}>
                                                                    <PackItem 
                                                                        packItem={packItem}
                                                                        stepNum={step.stepNum}
                                                                        handleAddPackItemClick={this.handleAddPackItemClick}
                                                                        productsPaid={step.productsPaid}
                                                                        isLonely={step.packItems.length === 1}
                                                                    />
                                                                </div>
                                                            )

                                                        })}
                                                        {(step.canSkip == 1)?this.skipStepButton():""}
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={style.Buttons}>
                            {this.state.error && <span style={{color: '#FF0000'}}>{t('please select number')}</span>}
                            {/* {this.state.currentStep !== this.state.stepsAmount && <button onClick={this.nextStep}>הבא</button>} */}
                            {/* {this.state.currentStep !== 1 && <button onClick={this.prevStep}>הקודם</button>} */}
                        </div>
                    </div>
                    
                }
            </div>
        )
    }
}

export default withTranslation()(PackItemUpdateForm);
